import {
    LISTE_USERS_ADD_MODAL,
    LISTE_USERS_COMMERCIAL_SUCCESS,
    LISTE_USERS_SUCCESS,
    LISTE_USERS_LOADING,
    LISTE_USERS_DISTRIBUTOR_SUCCESS,
    LISTE_USERS_TRACKING_ITEM_MODAL,
    LISTE_USERS_DISTR_FIND_DETAILSPLAFOND_LOADING,
    LISTE_USERS_DISTR_FIND_DETAILSPLAFOND_SUCCESS,
    LISTE_USERS_UPDATE_MODAL,LISTE_USERS_IMPORT_DISTRIBUTORSCSV_LOADING,
    LISTE_USERS_LOCALISATIONS_LOADING, LISTE_USERS_LOCALISATIONS_SUCCESS, LISTE_USERS_ITEM_UPDATE_LOADING, LISTE_USERS_IMPORT_DISTRIBUTORSCSV_MODAL
} from "../actions/types";

const initialState = {
    users: [],
    commercials: [],
    distributors: [],
    users_localisations: {},
    totalRows: 0,
    totalPages: 0,
    loading: false,
    itemTracking: null,
    itemDataUpdate: null,
    distr_plafonds: null,
    showAddModal: false,
    showUpdateModal: false,
    showTrackingItemModal: false,
    showImportDistributorsModal: false,
    loading_distrplafond: false,
    loadingUpdateUserPrivileges: false,
    loadingLocalisations: false,
    loadingImportDistributorsCSV: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LISTE_USERS_SUCCESS:
            return {
                ...state,
                users: payload.users,
                totalRows: payload.totalRows,
                totalPages: payload.totalPages,
                loading: false
            };
        case LISTE_USERS_COMMERCIAL_SUCCESS:
            return {
                ...state,
                commercials: payload.commercials,
                loading: false
            };
        case LISTE_USERS_DISTRIBUTOR_SUCCESS:
            return {
                ...state,
                distributors: payload.distributors,
                loading: false
            };

        case LISTE_USERS_LOADING:
            return {
                ...state,
                users: null,
                loading: payload.loading
            };

        case LISTE_USERS_ITEM_UPDATE_LOADING:
            return {
                ...state,
                loadingUpdateUserPrivileges: payload.loading
            };

        case LISTE_USERS_ADD_MODAL:
            return {
                ...state,
                showAddModal: !state.showAddModal
            };

        case LISTE_USERS_UPDATE_MODAL:
            return {
                ...state,
                showUpdateModal: payload?.show,
                itemDataUpdate: payload?.data
            };

        case LISTE_USERS_TRACKING_ITEM_MODAL:
            return {
                ...state,
                showTrackingItemModal: !state.showTrackingItemModal,
                itemTracking: payload.itemTracking
            };

        case LISTE_USERS_DISTR_FIND_DETAILSPLAFOND_LOADING:
            return {
                ...state,
                loading_distrplafond: payload.loading_distrplafond
            };

        case LISTE_USERS_LOCALISATIONS_LOADING:
            return {
                ...state,
                loadingLocalisations: payload.loading
            };

        case LISTE_USERS_LOCALISATIONS_SUCCESS:
            return {
                ...state,
                users_localisations: payload?.users_localisations
            };

        case LISTE_USERS_DISTR_FIND_DETAILSPLAFOND_SUCCESS:
            return {
                ...state,
                distr_plafonds: payload.distr_plafonds
            };

        case LISTE_USERS_IMPORT_DISTRIBUTORSCSV_MODAL:
            return {
                ...state,
                showImportDistributorsModal: payload.show,
            };
        case LISTE_USERS_IMPORT_DISTRIBUTORSCSV_LOADING:
            return {
                ...state,
                loadingImportDistributorsCSV: payload.loading
            };

        default:
            return state;
    }
}
