import {
    LISTE_ORDERS_ADD_DELIVERY_MODAL,
    LISTE_ORDERS_ADD_ECHEANCE_MODAL,
    LISTE_ORDERS_ADD_FAIL,
    LISTE_ORDERS_ADD_MODAL,
    LISTE_ORDERS_ADD_PAIEMENT_SUCCESS,
    LISTE_ORDERS_DETAILS_MODAL,
    LISTE_ORDERS_FAIL,
    LISTE_ORDERS_ITEM_DELIVERY_ADD_SUCCESS,
    LISTE_ORDERS_ITEM_DELIVERY_CONFIRM,
    LISTE_ORDERS_ITEM_DELIVERY_CONFIRM_LOADING,
    LISTE_ORDERS_ITEM_DELIVERY_FAIL,
    LISTE_ORDERS_ITEM_DELIVERY_SUCCESS, LISTE_ORDERS_ITEM_DELIVERYBACK_ADD,
    LISTE_ORDERS_ITEM_FAIL,
    LISTE_ORDERS_ITEM_SETSTATE_FAIL,
    LISTE_ORDERS_ITEM_SETSTATE_SUCCESS,
    LISTE_ORDERS_ITEM_STATE_LOADING,
    LISTE_ORDERS_ITEM_SUCCESS,
    LISTE_ORDERS_LOADING,
    LISTE_ORDERS_EXPORT_LOADING,
    LISTE_ORDERS_SUCCESS, LISTE_ORDERS_ITEM_DELIVERYBACK_SHOW
} from "../actions/types";

const initialState = {
    orders: [],
    deliveries: [],
    totalRows: 0,
    totalPages: 0,
    deliveryToConfirm: null,
    deliveryLineToAddLineBack: null,
    showDeliveryLineBackAddModal: false,
    showAddModal: false,
    showAddDeliveryModal: false,
    showAddEcheanceModal: false,
    showAddPaiementModal: false,
    showDetailsModal: false,
    showDeliveryConfirmModal: false,
    loading: false,
    loading_order: false,
    loading_order_export: false,
    loading_state: false,
    loading_delivery_confirm: false,
    loading_delivery_lineback: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LISTE_ORDERS_SUCCESS:
            return {
                ...state,
                orders: payload.orders,
                totalRows: payload.totalRows,
                totalPages: payload.totalPages,
                loading: false
            };
        case LISTE_ORDERS_FAIL:
            return {
                ...state,
                orders: payload.message,
                loading: false
            };
        case LISTE_ORDERS_ADD_FAIL:
            return {
                ...state,
                orders: payload.message,
                loading: false
            };
        case LISTE_ORDERS_LOADING:
            return {
                ...state,
                orders: null,
                loading: payload.loading
            };
        case LISTE_ORDERS_EXPORT_LOADING:
            return {
                ...state,
                loading_order_export: payload.loading
            };
        case LISTE_ORDERS_ITEM_STATE_LOADING:
            return {
                ...state,
                loading_state: payload.loading_state,
            };

        case LISTE_ORDERS_ADD_MODAL:
            return {
                ...state,
                showAddModal: !state.showAddModal
            };

        case LISTE_ORDERS_ITEM_DELIVERY_CONFIRM:
            return {
                ...state,
                showDeliveryConfirmModal: payload.show,
                deliveryToConfirm: payload.deliveryToConfirm
            };
        case LISTE_ORDERS_ITEM_DELIVERYBACK_ADD:
            return {
                ...state,
                showDeliveryLineBackAddModal: payload.show,
                deliveryLineToAddLineBack: payload.deliveryLineToAddLineBack
            };

        case LISTE_ORDERS_ITEM_DELIVERYBACK_SHOW:
            return {
                ...state,
                showDeliveryLineBackShowModal: payload.show
            };

        case LISTE_ORDERS_ADD_DELIVERY_MODAL:
            return {
                ...state,
                showAddDeliveryModal: !state.showAddDeliveryModal
            }

        case LISTE_ORDERS_ADD_ECHEANCE_MODAL:
            return {
                ...state,
                showAddEcheanceModal: !state.showAddEcheanceModal
            }

        case LISTE_ORDERS_ADD_PAIEMENT_SUCCESS:
            return {
                ...state,
                showAddPaiementModal: !state.showAddPaiementModal
            }

        case LISTE_ORDERS_ITEM_SUCCESS:
            return {
                ...state,
                order: payload.order,
            }
        case LISTE_ORDERS_ITEM_FAIL:
            return {
                ...state,
                order: null,
            }

        case LISTE_ORDERS_ITEM_SETSTATE_SUCCESS:
            return {
                ...state,
                order: payload.order,
            }

        case LISTE_ORDERS_ITEM_DELIVERY_ADD_SUCCESS:
            return {
                ...state,
                deliveries: payload.deliveries,
            }
        case LISTE_ORDERS_ITEM_DELIVERY_SUCCESS:
            return {
                ...state,
                deliveries: payload.deliveries,
            };
        case LISTE_ORDERS_ITEM_DELIVERY_CONFIRM_LOADING:
            return {
                ...state,
                loading_delivery_confirm: payload.loading_delivery_confirm,
            };

        case LISTE_ORDERS_DETAILS_MODAL:
            return {
                ...state,
                orderDetails: payload.orderDetails,
                showDetailsModal: !state.showDetailsModal
            }

        default:
            return state;
    }
}
