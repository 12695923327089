import {
    LISTE_OBJECTIVES_ADD_MODAL,
    LISTE_OBJECTIVES_FAIL,
    LISTE_OBJECTIVES_LOADING,
    LISTE_OBJECTIVES_SUCCESS,
    LISTE_OBJECTIVES_CRITERIA_SUCCESS,
    LISTE_OBJECTIVES_CRITERIA_FAIL,
} from "../actions/types";

const initialState = {
    objectives: [],
    criterias: [],
    showAddModal: false,
    totalRows: 0,
    totalPages: 0,
    loading: false
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LISTE_OBJECTIVES_SUCCESS:
            return {
                ...state,
                objectives: payload.objectives,
                loading: false
            };
        case LISTE_OBJECTIVES_FAIL:
            return {
                ...state,
                objectives: payload.message,
                loading: false
            };
        case LISTE_OBJECTIVES_CRITERIA_SUCCESS:
            return {
                ...state,
                criterias: payload.criterias,
            };
        case LISTE_OBJECTIVES_CRITERIA_FAIL:
            return {
                ...state,
                criterias: [],
            };
        case LISTE_OBJECTIVES_LOADING:
            return {
                ...state,
                objectives: null,
                loading: payload.loading
            };

        case LISTE_OBJECTIVES_ADD_MODAL:
            return {
                ...state,
                showAddModal: !state.showAddModal
            }

        default:
            return state;
    }
}
