import {
    LISTE_TYPEECHEANCE_SUCCESS, LISTE_TYPEECHEANCE_LOADING
} from "../actions/types";

const initialState = {
    typeEcheances: [],
    loading: false
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LISTE_TYPEECHEANCE_SUCCESS:
            return {
                ...state,
                typeEcheances: payload.typeEcheances,
                loading: false
            };
        case LISTE_TYPEECHEANCE_LOADING:
            return {
                ...state,
                loading: payload.loading
            };

        default:
            return state;
    }
}
