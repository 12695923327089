import {
    LISTE_MARQUE_SUCCESS,
    LISTE_MARQUE_FAIL,
    LISTE_MARQUE_LOADING,
    LISTE_MARQUE_ADD_LOADING,
    LISTE_MARQUE_UPDATE_LOADING, LISTE_MARQUE_ADD_MODAL, LISTE_MARQUE_UPDATE_MODAL
} from "../actions/types";

const initialState = {
    marques: [],
    totalRows: 0,
    totalPages: 0,
    itemDataUpdate: null,
    loading: false,
    loadingAdd: false,
    loadingUpdate: false,
    showAddModal: false,
    showUpdateModal: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LISTE_MARQUE_SUCCESS:
            return {
                ...state,
                marques: payload.marques,
                totalRows: payload.totalRows,
                totalPages: payload.totalPages,
                loading: false
            };
        case LISTE_MARQUE_FAIL:
            return {
                ...state,
                marques: payload.message,
                loading: false
            };
        case LISTE_MARQUE_LOADING:
            return {
                ...state,
                // marques: payload?.loading ? [] : state.marques,
                loading: payload.loading
            };


        case LISTE_MARQUE_ADD_LOADING:
            return {
                ...state,
                loadingAdd: payload.loadingAdd
            };

        case LISTE_MARQUE_UPDATE_LOADING:
            return {
                ...state,
                loadingUpdate: payload.loadingUpdate
            };

        case LISTE_MARQUE_ADD_MODAL:
            return {
                ...state,
                showAddModal: payload?.show,
            };

        case LISTE_MARQUE_UPDATE_MODAL:
            return {
                ...state,
                showUpdateModal: payload?.show,
                itemDataUpdate: payload?.data
            };

        default:
            return state;
    }
}
