import {LISTE_DISTRIBUTIONCHANNEL_FAIL, LISTE_DISTRIBUTIONCHANNEL_SUCCESS, LISTE_DISTRIBUTIONCHANNEL_LOADING } from "../actions/types";

const initialState = {
    distributionchannel: [],
    totalRows: 0,
    totalPages: 0,
    loading: false
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LISTE_DISTRIBUTIONCHANNEL_SUCCESS:
            return {
                ...state,
                distributionchannels: payload.distributionchannels,
                loading: false
            };
        case LISTE_DISTRIBUTIONCHANNEL_FAIL:
            return {
                ...state,
                distributionchannels: payload.message,
                loading: false
            };
        case LISTE_DISTRIBUTIONCHANNEL_LOADING:
            return {
                ...state,
                distributionchannels: null,
                loading: payload.loading
            };

        default:
            return state;
    }
}
