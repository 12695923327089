import {
    LISTE_ROUTEPLAN_SUCCESS,
    LISTE_ROUTEPLAN_FAIL,
    LISTE_ROUTEPLAN_LOADING,
    LISTE_ROUTEPLAN_ADD_LOADING,
    LISTE_ROUTEPLAN_UPDATE_LOADING, LISTE_ROUTEPLAN_ADD_MODAL, LISTE_ROUTEPLAN_UPDATE_MODAL
} from "../actions/types";

const initialState = {
    routeplans: [],
    totalRows: 0,
    totalPages: 0,
    itemDataUpdate: null,
    loading: false,
    loadingAdd: false,
    loadingUpdate: false,
    showAddModal: false,
    showUpdateModal: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LISTE_ROUTEPLAN_SUCCESS:
            return {
                ...state,
                routeplans: payload.routeplans,
                totalRows: payload.totalRows,
                totalPages: payload.totalPages,
                loading: false
            };
        case LISTE_ROUTEPLAN_FAIL:
            return {
                ...state,
                routeplans: payload.message,
                loading: false
            };
        case LISTE_ROUTEPLAN_LOADING:
            return {
                ...state,
                // routeplans: payload?.loading ? [] : state.routeplans,
                loading: payload.loading
            };


        case LISTE_ROUTEPLAN_ADD_LOADING:
            return {
                ...state,
                loadingAdd: payload.loadingAdd
            };

        case LISTE_ROUTEPLAN_UPDATE_LOADING:
            return {
                ...state,
                loadingUpdate: payload.loadingUpdate
            };

        case LISTE_ROUTEPLAN_ADD_MODAL:
            return {
                ...state,
                showAddModal: payload?.show,
            };

        case LISTE_ROUTEPLAN_UPDATE_MODAL:
            return {
                ...state,
                showUpdateModal: payload?.show,
                itemDataUpdate: payload?.data
            };

        default:
            return state;
    }
}
