import {
    SET_MESSAGE,
    CLEAR_MESSAGE,
    LISTE_ORDERS_ITEM_SETSTATE_FAIL,
    LISTE_ORDERS_ITEM_FAIL,
    LISTE_ORDERS_ITEM_DELIVERY_FAIL,
    LISTE_ORDERS_ITEM_DELIVERY_SUCCESS,
    LISTE_TYPEECHEANCE_FAIL,
    LISTE_USERS_DISTR_FIND_DETAILSPLAFOND_FAIL,
    LISTE_PROMOTIONS_FAIL,
    LISTE_PROMOTIONS_ADD_FAIL,
    LISTE_PROMOTIONS_ITEM_SETSTATUS_FAIL,
    LISTE_ARTICLES_WITHCHANNEL_FAIL,
    LISTE_ARTICLES_FAIL,
    LISTE_USERS_FAIL,
    LISTE_GROUPUSERS_FAIL,
    LISTE_MYAPPSETTINGS_ONE_FAIL,
    LISTE_USERS_LOCALISATIONS_FAIL,
    LISTE_STATISTICS_ORDERSTATELEVELPROGRESS_FAIL,
    LISTE_STATISTICS_DASHBOARDCARDS_FAIL, LISTE_USERS_ITEM_UPDATE_FAIL, LISTE_ARTICLES_IMPORTCSV_FAIL, LISTE_ARTICLES_UPDATE_ARTICLECHANNEL_FAIL
} from "../actions/types";

const initialState = {
    message: '',
    message_order_item_state: '',
    message_order_item: '',
    message_order_item_deliveries: '',
    message_distrplafond: '',
    message_promotions: '',
    message_promotions_add: '',
    message_promotions_item_status: '',
    message_articleschannel: '',
    message_myappsetting: '',
    message_update_userappsetting: '',
    message_users_localisations: '',
    message_articles_importcsv: '',
    message_statistics_orderstatelevelprogress: '',
    message_statistics_dashboardcards: '',
    message_update_articlechannel: ''
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_MESSAGE:
            return { message: payload };

        case LISTE_ORDERS_ITEM_SETSTATE_FAIL:
            return { message_order_item_state: payload };

        case LISTE_ORDERS_ITEM_FAIL:
            return { message_order_item: payload };

        case LISTE_ORDERS_ITEM_DELIVERY_FAIL:
            return { message_order_item_deliveries: payload };

        case LISTE_ORDERS_ITEM_DELIVERY_SUCCESS:
            return { message_order_item_deliveries: '' };

        case LISTE_TYPEECHEANCE_FAIL:
            return { message_typeecheances: payload };

        case LISTE_USERS_DISTR_FIND_DETAILSPLAFOND_FAIL:
            return { message_distrplafond: payload };

        case LISTE_PROMOTIONS_FAIL:
            return { message_promotions: payload };

        case LISTE_PROMOTIONS_ADD_FAIL:
            return { message_promotions_add: payload };

        case LISTE_PROMOTIONS_ITEM_SETSTATUS_FAIL:
            return { message_promotions_item_status: payload };

        case LISTE_ARTICLES_WITHCHANNEL_FAIL:
            return { message_articleschannel: payload };

        case LISTE_ARTICLES_FAIL:
            return { message_articles: payload };

        case LISTE_USERS_FAIL:
            return { message_users: payload };

        case LISTE_GROUPUSERS_FAIL:
            return { message_groupusers: payload };

        case LISTE_MYAPPSETTINGS_ONE_FAIL:
            return { message_myappsetting: payload };

        case LISTE_USERS_ITEM_UPDATE_FAIL:
            return { message_update_userappsetting: payload };

        case LISTE_USERS_LOCALISATIONS_FAIL:
            return { message_users_localisations: payload };

        case LISTE_ARTICLES_IMPORTCSV_FAIL:
            return { message_articles_importcsv: payload };

        case LISTE_STATISTICS_ORDERSTATELEVELPROGRESS_FAIL:
            return { message_statistics_orderstatelevelprogress: payload };

        case LISTE_STATISTICS_DASHBOARDCARDS_FAIL:
            return { message_statistics_dashboardcards: payload };

        case LISTE_ARTICLES_UPDATE_ARTICLECHANNEL_FAIL:
            return { message_update_articlechannel: payload };

        case CLEAR_MESSAGE:
            return { message: "" };

        default:
            return state;
    }
}
