import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT, LOGOUT_FAIL,
} from "../actions/types";
import {LOCAL_STORAGE} from "../constants";
import {reactLocalStorage} from "reactjs-localstorage";

// var storageUser = reactLocalStorage.getObject(LOCAL_STORAGE.USER);
// console.log("Reducer:Auth storageUser", storageUser);

// const initialState = storageUser
//     ? { isLoggedIn: true, storageUser }
//     : { isLoggedIn: false, user: null };
const initialState = { isLoggedIn: false, user: null };

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REGISTER_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
            };
        case REGISTER_FAIL:
            return {
                ...state,
                isLoggedIn: false,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
                message: '',
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                message: payload.message,
            };
        case LOGOUT_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                message: '',
            };
        case LOGOUT:
            state.user = null;
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        default:
            return state;
    }
}
