import {
    LISTE_PROMOTIONS_ADD_LOADING,
    LISTE_PROMOTIONS_ADD_MODAL,
    LISTE_PROMOTIONS_ITEM_SETSTATUS_ACTIVER_MODAL, LISTE_PROMOTIONS_ITEM_SETSTATUS_DESACTIVER_MODAL,
    LISTE_PROMOTIONS_ITEM_SETSTATUS_LOADING,
    LISTE_PROMOTIONS_LOADING,
    LISTE_PROMOTIONS_SUCCESS,
} from "../actions/types";

const initialState = {
    promotions: [],
    deliveries: [],
    itemPromoSetStatus: null,
    totalRows: 0,
    totalPages: 0,
    showAddModal: false,
    showItemSetStatusActiverModal: false,
    showItemSetStatusDesactiverModal: false,
    loading: false,
    loadingAdd: false,
    loadingItemStatus: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LISTE_PROMOTIONS_SUCCESS:
            return {
                ...state,
                promotions: payload.promotions,
                totalRows: payload.totalRows,
                totalPages: payload.totalPages,
                loading: false
            };
        case LISTE_PROMOTIONS_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
        case LISTE_PROMOTIONS_ADD_LOADING:
            return {
                ...state,
                loadingAdd: payload.loadingAdd,
            };

        case LISTE_PROMOTIONS_ADD_MODAL:
            return {
                ...state,
                showAddModal: !state.showAddModal
            };

        case LISTE_PROMOTIONS_ITEM_SETSTATUS_LOADING:
            return {
                ...state,
                loadingItemStatus: payload.loadingItemStatus
            };

        case LISTE_PROMOTIONS_ITEM_SETSTATUS_ACTIVER_MODAL:
            return {
                ...state,
                showItemSetStatusActiverModal: payload.showItemSetStatusActiverModal,
                itemPromoSetStatus: payload.itemPromoSetStatus,
            };

        case LISTE_PROMOTIONS_ITEM_SETSTATUS_DESACTIVER_MODAL:
            return {
                ...state,
                showItemSetStatusDesactiverModal: payload.showItemSetStatusDesactiverModal,
                itemPromoSetStatus: payload.itemPromoSetStatus,
            };

        default:
            return state;
    }
}
