import {
    LISTE_STATISTICS_DASHBOARDCARDS_LOADING,
    LISTE_STATISTICS_DASHBOARDCARDS_SUCCESS,
    LISTE_STATISTICS_ORDERSTATELEVELPROGRESS_LOADING,
    LISTE_STATISTICS_ORDERSTATELEVELPROGRESS_SUCCESS
} from "../actions/types";

const initialState = {
    dashboardcards: [],
    oderstalevelprogress: [],
    loading: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LISTE_STATISTICS_DASHBOARDCARDS_SUCCESS:
            return {
                ...state,
                dashboardcards: payload.dashboardcards
            };

        case LISTE_STATISTICS_ORDERSTATELEVELPROGRESS_SUCCESS:
            return {
                ...state,
                oderstalevelprogress: payload.oderstalevelprogress
            };

        case LISTE_STATISTICS_DASHBOARDCARDS_LOADING:
            return {
                ...state,
                loading: payload.loading
            };
            
        case LISTE_STATISTICS_ORDERSTATELEVELPROGRESS_LOADING:
            return {
                ...state,
                loading: payload.loading
            };

        default:
            return state;
    }
}
