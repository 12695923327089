import { SET} from "../actions/types";

const initialState = { sidebarShow: 'responsive' };


export default function (state = initialState, action) {
    const { type, sidebarShow } = action;

    switch (type) {
        case SET:
            return {...state, sidebarShow: sidebarShow === 'responsive' ? false : 'responsive' };
        default:
            return state;
    }
}
