import {
    LISTE_GROUPUSERS_SUCCESS,
    LISTE_GROUPUSERS_FAIL,
    LISTE_GROUPUSERS_LOADING
} from "../actions/types";

const initialState = {
    groupusers: [],
    loading: false
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LISTE_GROUPUSERS_SUCCESS:
            return {
                ...state,
                groupusers: payload.groupusers,
                loading: false
            };

        case LISTE_GROUPUSERS_LOADING:
            return {
                ...state,
                loading: payload.loading
            };

        default:
            return state;
    }
}
